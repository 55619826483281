import React, { useState } from "react";
import * as styles from "./faqSection.module.scss";
import Faq from "../../atoms/Faq";

const FaqSection = () => {
  const [faqs, setfaqs] = useState([
    {
      question: "What inspired MASTER WiZR?",
      answer:
        "Driven by the conviction that the world deserves a better way to present share and meet, we’ve taken the best of world leading design tools - Apple, Spotify Netflx and Microsoft - and put them all in one place. MASTER WiZR democratizes beautiful intuitive user experience design so that everyone can express their ideas better.",
      open: true,
    },
    {
      question: "How much does it cost?",
      answer:
        "We offer both Software as a Service (SaaS) and Presentations as a Service (PaaS). We made this affordable for everyone: students, freelancers, small businesses to large enterprises. License prices vary from 10-80 USD per month depending on use and number of licenses. We also offer a free version to get started. Just simply enquire to get started. Our PaaS offering is given in dialogue with prospective users.",
      open: false,
    },
    {
      question: "What can I do on MASTER WiZR?",
      answer:
        "MASTER WiZR is a cloud-based presentation system consisting of two modules: LIBRARY and STUDIO. The LIBRARY allows you clever ways to organize and present the full catalog of contents. The STUDIO lets you pick presentations and videos from the LIBRARY to customize material and share in new inspiring ways. MASTER WiZR will allow you to improve how your material look, is organized, presented, and shared. Our vision does not only give you a good tool, but to revolutionize presentations.",
      open: false,
    },
    {
      question: "What makes MASTER WiZR unique?",
      answer:
        "We aim to contribute your material never having looked better, been better structured and better to share. MASTER WiZR aims to be the world’s premier presentation and meeting tool and this means you will have stronger impact and be more productive in your communications. See the before and after on your own material.",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        }
        // If you want to close other FAQs when you click one of them just uncomment the part below
        // else {
        //     faq.open = false;
        // }

        return faq;
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <h3>FAQ</h3>
        <h2>In case you missed anything</h2>
        {faqs.map((faq, i) => (
          <Faq index={i} faq={faq} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </div>
  );
};

export default FaqSection;
