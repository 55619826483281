import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./presentationPortal.module.scss";
import StyledButton from "../../atoms/StyledButton";
import { IoIosArrowForward } from "react-icons/io";
function index() {
  return (
    // <div className={styles.presentationPortal}>
    //   <div   className={styles.content}>
    //     <div className={styles.title}>
    //       <h1>Simple to use</h1>
    //     </div>
    //     <div  className={styles.img}>
    //       <StaticImage src="../../../images/presationPortal.png" />
    //     </div>
    //     <div className={styles.desc}>
    //       <p>
    //       MASTER WiZR was designed with the customer in mind. It is designed to be intuitive and easy to use - encouraging team-wide adoption.
    //       </p>
    //       <StyledButton  link="/get-started" title="Get Started"
    //       icon={<IoIosArrowForward />}
    //       extraClass="nobgLink"
    //       />
    //     </div>
    //   </div>
    // </div>
    <div className={styles.presentationPortal}>
      <div className={styles.content}>
      
        <div className={styles.desc}>
          <h1>Your Virtual Pressence</h1>
          <p>Beyond web and social media explore new ways to:</p>
          <p>- presenting ideas you care about</p>
          <p>- holding engaging video meetings</p>
          <p>- communicating much more visually </p>
        </div>
      </div>

    </div>
  );
}

export default index;