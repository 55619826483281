import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import * as styles from "./offers.module.scss";
import { StudioLight, Studio, FullVersion,Student } from "./cards";

function Index() {
  const [index, setIndex] = useState(0);
  const [width, setWidth] = useState(null);
  const [colorTab, setColorTab] = useState("#0693DA");

  useEffect(()=>{
    setWidth(window.innerWidth);
  },[])
  const handleChange = (event,value) => {
    if (value === 0) {
      setColorTab("#0693DA");
    } else if (value === 1) {
      setColorTab("#1F3D53");
    } 
    else if (value === 2) {
      setColorTab("#2E6055");
    }
    else {
      setColorTab("#EC7600");
    }
    setIndex(value);
  };
  const handleChangeIndex = (index) => {
    if (index === 0) {
      setColorTab("#0693DA");
    } else if (index === 1) {
      setColorTab("#1F3D53");
    } 
    else if (index === 2) {
      setColorTab("#2E6055");
    }
    else {
      setColorTab("#EC7600");
    }
    setIndex(index);
  };

  const mobileView = () => {
    return (
      <div style={{ display: "inline-grid" }}>
        <Tabs
          value={index}
          fullWidth
          onChange={handleChange}
          TabIndicatorProps={{
            style: { background: colorTab, color: colorTab },
          }}
          className={styles.tabs}
        >
          <Tab
            className={index === 0 && styles.selected}
            label="Studio Light"
          />
           <Tab
            className={index === 1 && styles.selected}
            label="Student/Freelancer"
          />
          <Tab className={index === 2 && styles.selected} label="Studio" />
          <Tab
            className={index === 3 && styles.selected}
            label="Full Version"
          />
        </Tabs>
        <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
          <StudioLight />
          <Student />
          <Studio />
          <FullVersion />
        </SwipeableViews>
      </div>
    );
  };

  const desktopView = () => {
    return (
      <div className={styles.desktopContainOffers}>
        <StudioLight />
        <Student />
          <Studio />
        <FullVersion />
      </div>
    );
  };
  return (
    <div className={styles.offersContainer}>
      <div className={styles.description}>
        <h1>Master offers</h1>
        <p>MASTER WiZR is a new communication tool that revolutionizes the way you engage professionally.
        </p>
      </div>
      {width && width < 1000 ? mobileView() : desktopView()}
    </div>
  );
}

export default Index;
