import React from 'react';
import * as styles from './service.module.scss'

const Service = ({extraClass,animation,delay, number, title, description}) => {
    return (
        <div data-aos={animation} data-aos-delay={delay} className={`${styles.container} ${styles[extraClass]}  `}>
            <div className={styles.logo}>
                <p>{number}</p>
            </div>
            <div className={styles.text}>
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default Service;
