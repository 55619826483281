import { Link } from "gatsby";
import React from "react";
import { faCheck,faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as styles from "./offersCard.module.scss";

function index({ title, price, extraClass , desc, infromation, benefits, to, color,bgColor,delay,button , cursor}) {
  return (
    <div  className={`${styles.containerCards} ${styles[extraClass]}`}>
      <div className={styles.descCards}>
        <h1 style={{ color: color, borderColor: color }}>
          {title}
        </h1>
        <p className={styles.price}>
          <span style={{color:color}}>${price}</span>/month
        </p>
        <p className={styles.desc}>{desc}</p>
        <p className={styles.information}>{infromation}</p>
        <div className={styles.benefits}>
          <h3 style={{ color: color }}>Benefits</h3>
          {benefits.map((res) => (
            <p key={res.text}>
              <FontAwesomeIcon icon={faCheck} color={color}/>
              <span>{res.nr}</span>
              {res.text}
            </p>
          ))}
        </div>
      </div>
      <div className={styles.button} style={{backgroundColor:bgColor , cursor: cursor}}>
        <Link to={to}>{button} <FontAwesomeIcon icon={faChevronRight} color='#fff'/></Link>
      </div>
     
    </div>
  );
}

export default index;
