import React from 'react';
import * as styles from "./HorizontalScroll.module.scss";
import {StaticImage} from "gatsby-plugin-image";

const HorizontalScroll = () => {
    return (
        <div className={styles.scrollContainer}>
            <div className={`${styles.scroll} ${styles.upper}`}>
                <StaticImage src="../../../images/SlideA-horizontal-scroll.png" alt='Master Wizr' className={styles.image} />
                <StaticImage src="../../../images/SlideB-horizontal-scroll.png" alt='Master Wizr' className={styles.image} />
                <StaticImage src="../../../images/SlideC-horizontal-scroll.png" alt='Master Wizr' className={styles.image} />
                <StaticImage src="../../../images/Slide1-horizontal-scroll.png" alt='Master Wizr' className={styles.image} />
                <StaticImage src="../../../images/Slide2-horizontal-scroll.png" alt='Master Wizr' className={styles.image} />
                <StaticImage src="../../../images/Slide3-horizontal-scroll.png" alt='Master Wizr' className={styles.image} />
            </div>
            <div className={`${styles.scroll} ${styles.lower}`}>
                <StaticImage src="../../../images/3-horizontal-scroll.webp" alt='Master Wizr' className={styles.image} />
                <StaticImage src="../../../images/4-horizontal-scroll.webp" alt='Master Wizr' className={styles.image} />
                <StaticImage src="../../../images/4b-horizontal-scroll.webp" alt='Master Wizr' className={styles.image} />
                <StaticImage src="../../../images/5-horizontal-scroll.webp" alt='Master Wizr' className={styles.image} />
                <StaticImage src="../../../images/6-horizontal-scroll.webp" alt='Master Wizr' className={styles.image} />
                <StaticImage src="../../../images/8-horizontal-scroll.webp" alt='Master Wizr' className={styles.image} />
            </div>
        </div>
    );
};

export default HorizontalScroll;
