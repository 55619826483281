import React from "react";
import * as styles from './bannerTekst.module.scss';

function index() {
  return (
    <div className={styles.containTitle}>
      <h1 data-aos="zoom-in" data-aos-delay="100">The Future of Communication</h1>
      <p data-aos="zoom-in" data-aos-delay="200">
      Magically bringing people and teams together<br/>
         Remotely and in-person.
      </p>
    </div>
  );
}

export default index;
