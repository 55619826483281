import React, { useEffect, useCallback , useState } from 'react';
import * as styles from './controlPanel.module.scss';
import {StaticImage} from "gatsby-plugin-image";
import StyledButton from '../../atoms/StyledButton';
import {IoIosArrowForward} from 'react-icons/io';
import img from '../../../images/screenn.png'
const ControlPanel = () => {
    const [margin, setMargin] = useState(0);

    const handleNavigation = useCallback(
        (e) => {
            const window = e.currentTarget;
            if(window.pageYOffset >= 1550 && window.pageYOffset <= 2350){
                let value = -200;
                if(value >= -200 && value <= 0){
                    value = -(2350-window.pageYOffset)/9;
                    setMargin(value);
                }
            }
        },
        []
    );

    useEffect(() => {
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.upper}>
                    <h2 className={styles.title}>MASTER WiZR Product Features</h2>
                    <p className={styles.description}>Think, present and share better with WiZR’s All-in-One System.Invite more participation into your process , Streamline and display your content selectively , Make it easier to manage data and access assets , Work in real time , Increase productivity  </p>
                    <div className={styles.button}>
                        <StyledButton title="Get free trial" extraClass="bgShadow" icon={<IoIosArrowForward />} link="/get-started" email={true}/>
                    </div>
                </div>
                <img src={img} />
            </div>
            </div>
    );
};

export default ControlPanel;