import React from 'react';
import Logo from '../../../images/favicon.png';
import * as styles from './styles.module.scss';
import StyledButton from '../StyledButton/index';
function OpenPositions(){
    return(
        <div className={styles.positionSharedPart}>
        <img src={Logo} />
        <div className={styles.content}>
            <h2>Join Our <span>M</span>ission</h2>
             <p>Let's grow together !</p>
             <div data-aos="zoom-in">
             <StyledButton title="Open Job Positions" icon extraClass="bigBtn"  link="/hiring/#positions"/>
             </div>
        </div>
        </div>
    )
};
export default OpenPositions ;