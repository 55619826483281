// extracted by mini-css-extract-plugin
export var arrow = "IconSlider-module--arrow--tM33u";
export var arrows = "IconSlider-module--arrows--yu+Yj";
export var container = "IconSlider-module--container--6-yem";
export var desc = "IconSlider-module--desc--3T+20";
export var description = "IconSlider-module--description--6AZpP";
export var icon = "IconSlider-module--icon--Bwdma";
export var iconImg = "IconSlider-module--iconImg--SIRRF";
export var icons = "IconSlider-module--icons--8YnaW";
export var innerContainer = "IconSlider-module--innerContainer---FAhf";
export var leftContainer = "IconSlider-module--leftContainer--SJfAi";
export var rightContainer = "IconSlider-module--rightContainer--znXR2";
export var selected = "IconSlider-module--selected--8PsXh";
export var slider = "IconSlider-module--slider--IoJpG";
export var sliderImg = "IconSlider-module--sliderImg--+m0m+";