import React from "react";
import OffersCard from "../../atoms/offersCard";
export const StudioLight = () => {
  return (
    <OffersCard
      title="Free Trial"
      price={0}
      desc="Get an opportunity to test it out. Specially meant for students and freelancers."
      infromation="Transition to Full License with a few clicks and keep all your content"
      extraClass = "active"
      benefits={[
        {
          text: "Full access",
        },
        {
          text: "1 week",
        },
        {
          text: "Easy transition to paid",
        },
      ]}
      to="/get-started"
      color="#0693DA"
      bgColor="#0693DA"
      button="Enquire Now"
      cursor= "pointer"
    />
  );
};
export const Student = () => {
  return (
    <OffersCard
      title="Student/Freelancer"
      price={12}
      desc="Use it for homework, interviews and showcasing work as a freelancer."
      infromation="This is a person license and with some restriction of features and content"
      benefits={[
        {
          text: "Up to 10 Show Rooms",
        },
        {
          text: "Up to 100MB Library content",
        },
        {
          text: "1 Portal",
        },
      ]}
      to="#"
      color="#1F3D53"
      bgColor="transparent"
      // button="Subscribe Now"

    />
  );
};

export const Studio = () => {
  return (
    <OffersCard
      title="Standard License"
      price={39}
      desc="Save by getting started right away. Unlimited presentations Show Rooms"
      infromation="Transition to Full Lisence with a few clicks and keep all your content"
      benefits={[
        {
          nr: "Unlimited",
          text: "Show Rooms",
        },
        {
          nr: "Unlimited",
          text: "Cover Pages",
        },
        {
          text: "Up to 3 Portals",
        },
      ]}
      to="#"
      color="#2E6055"
      bgColor="transparent"
      // button="Subscribe Now"

    />
  );
};

export const FullVersion = () => {
  return (
    <OffersCard
      title="Premium License"
      price={50}
      desc="Save by getting started right away. Unlimited presentations Show Rooms"
      infromation="Special content creation packages available part of the program"

      benefits={[
        {
          nr: "Unlimited",
          text: "Show Rooms",
        },
        {
          nr: "Unlimited",
          text: "Cover Pages",
        },
        {
          nr: "Unlimited",
          text: "Portals",
        },
        {
          nr:"Video Beta trial"
        }
      ]}
      to="#"
      color="#EC7600"
      // button="Subscribe Now"
      bgColor="transparent"
    />
  );
};