import React, {useCallback, useEffect, useState} from "react";
import * as styles from './explore.module.scss';
import WizrIcon from '../../../svg/exploreWizr.svg';
import StyledButton from '../StyledButton';
import {FaAngleRight } from 'react-icons/fa';
const Explore = () => {
    const [margin, setMargin] = useState(0);

    const handleNavigation = useCallback(
        (e) => {
            const window = e.currentTarget;
            if(window.pageYOffset >= 190 && window.pageYOffset <= 800){
                let value = -200;
                if(value >= -200 && value <= 0){
                    value = -(800-window.pageYOffset)/3;
                    setMargin(value);
                }
            }
        },
        []
    );

    useEffect(() => {
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);
    return(
      <div className={styles.container}>
          <div style={{ position:'relative', top:`${-margin}px` }}>
              <WizrIcon className={styles.logo} />
          </div>
          <div className={styles.description}>
              <h2>About MASTER WiZR</h2>
              <p className={styles.boldText}><b>Stand out - Connect better - Empower your messages.</b></p>
              <p>We inspire companies and individuals to project themselves with innovative communication solutions. MASTER WiZR is the world’s premier presentation and meeting platform to level the playing field. Now small fish can look big, and big fish can look nimble. <b className={styles.boldText2}>We empower all to Shine professionally.</b></p>
              <StyledButton icon={<FaAngleRight  />} title="About Us" link="/about-us" extraClass="nobgLink"/>
              <hr/>
          </div>
      </div>
    );
}

export default Explore;