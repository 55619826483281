import React from 'react';
import * as styles from './newsCard.module.scss';
import {StaticImage} from 'gatsby-plugin-image'
import NewsTitle from "../../atoms/newsTitle";
import { Link } from 'gatsby';

const NewsCard = ({title, link, style}) => {

    return (
        <div className={styles.card} style={style}>
            <Link to="/blog/digitizing-sales-shorter-cycles" className={styles.cardHolder}>
            <StaticImage className={styles.img} alt="News Image" src="../../../images/homepg3.jpg" />
            <NewsTitle title="Digitizing Sales" link={link} />
        </Link>
        <Link to="/blog/digitzing-meetings-better-outcomes" className={styles.cardHolder}>
            <StaticImage className={styles.img} alt="News Image" src="../../../images/homepg.jpg" />
            <NewsTitle title="Digitizing Meetings" link={link} />
        </Link>
        <Link to="/blog/presentation-streaming-is-the-answer" className={styles.cardHolder}>
            <StaticImage className={styles.img} alt="News Image" src="../../../images/homepg2.jpg" />
            <NewsTitle title="Presentation Streaming" link={link} />
        </Link>


        </div>
    );
};

export default NewsCard;
