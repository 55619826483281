import React from "react";
import * as styles from "./presentLike.module.scss";
import StyledButton from "../../atoms/StyledButton";
import { IoIosArrowForward } from "react-icons/io";

function index() {
  return (
    <div className={styles.presentLike}>
      <div className={styles.title}>
        <h1>Connect meaningfully like never before</h1>
      </div>

      <p>
      We are changing the way people present for the better. Look, work and connect like you’re in the same room. It is all about how you connect with clarity of your message and deliver it inspiringly. <br/>
      Brain to Brain - Heart to Heart - Locally – Virtually - Globally <br/> Join us.

      </p>
      <div className={styles.links}>
        {/* <StyledButton
          link="https://beta-studio.mwstream.com/control-panel/cover-pages"
          title="Try free trial today"
          icon={<IoIosArrowForward />}
          extraClass="graybtn"
        /> */}
      </div>
    </div>
  );
}

export default index;