import React, { useEffect, useCallback, useState } from "react";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as styles from "./tutorialVideos.module.scss";
import img from "../../../images/front-tutorial.webp";
import Tab from "../../atoms/videoTab";

function Index() {
  const [margin, setMargin] = useState(2);
  const [url, setUrl] = useState(null);
  const [active, setActive] = useState(1);
  const [playBtn, setPlayBtn] = useState(true);
  const [autoPlay, setAutoPlay] = useState(false);

  const handleNavigation = useCallback((e) => {
    const window = e.currentTarget;
    if (window.pageYOffset >= 2700) {
      setMargin(0);
    } else if (window.pageYOffset <= 2699) {
      setMargin(2);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  function playVideo(id) {
    setActive(id);
    setPlayBtn(false);
    setAutoPlay(true);
    if (id === 1) {
      setUrl(
        "https://masterwizr.com/wp-content/uploads/2020/10/WiZR-1-2-1-1-1-1-1.mp4"
      );
    } else if (id === 2) {
      setUrl(
        "https://masterwizr.com/wp-content/uploads/2020/10/WiZR-3-1-1-1-1.mp4"
      );
    } else if (id === 3) {
      setUrl(
        "https://masterwizr.com/wp-content/uploads/2020/12/WiZR-Video-4-1-1-1-1-2.mp4"
      );
    } else {
      setUrl(
        "https://masterwizr.com/wp-content/uploads/2020/12/WiZR-Slide-costumizer-1-1-2-1.mp4"
      );
    }
  }

  return (
    <div className={styles.layout}>
      <div className={styles.tutorialVideosContainer}>
        <div className={styles.description}>
          <h1>MASTER WiZR in Action</h1>
          <p>
            <b>Videos: </b>Get a quick overview here.
            Here you can see a long-form video or jump to
            the section you find most interesting and keep coming back for more.
          </p>
        </div>
        <div className={styles.img}>
          {playBtn && (
            <FontAwesomeIcon
              onClick={() => playVideo(1)}
              icon={faPlay}
              color="#EC7600"
            />
          )}
          <video
            src={url}
            poster={img}
            autoPlay={autoPlay}
            controls={url ? true : false}
          />
        </div>
        <div className={styles.tabs}>
          <Tab
            text="1. INTRO"
            secondText="Future of presentations"
            active={active === 1 && true}
            margin={margin}
            id={1}
            click={playVideo}
            desc='With MASTER WiZR you will have a whole new way to present, share and organize material that matters.'
          />
          <Tab
            text="2. STARTUP"
            secondText="Ease of use"
            margin={margin}
            id={2}
            active={active === 2 && true}
            click={playVideo}
            desc='The first steps to get started with an account and your first content. Simple and smart.'
          />
          <Tab
            text="3. SHARING CENTER"
            secondText="Power tool"
            margin={margin}
            id={3}
            active={active === 3 && true}
            click={playVideo}
            desc='Likely the best sharing experience of its kind. Share 1,10 or 100 file links in seconds'
          />
          <Tab
            text="4. SLIDE CUSTOMIZER"
            secondText="Time saver"
            margin={margin}
            id={4}
            active={active === 4 && true}
            click={playVideo}
            desc='Select pictures from many presentation in seconds. Save time and enjoy.'
          />
        </div>
      </div>
    </div>
  );
}

export default Index;