// extracted by mini-css-extract-plugin
export var containVisual = "visualConnection-module--containVisual--hQi2S";
export var description = "visualConnection-module--description--2hACD";
export var desktopV = "visualConnection-module--desktopV--g3rxv";
export var link = "visualConnection-module--link--bMbqi";
export var logo = "visualConnection-module--logo--4ekXp";
export var overlayBackground = "visualConnection-module--overlayBackground--gphDr";
export var overlayButon = "visualConnection-module--overlayButon--voG2v";
export var overlayImg = "visualConnection-module--overlayImg--xtVEY";
export var visualConnection = "visualConnection-module--visualConnection--1CKnJ";
export var visualContainer = "visualConnection-module--visualContainer--U6wy8";