import React from "react";
import * as styles from "./virtualPresence.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import StyledButton from "../../atoms/StyledButton";
import {IoIosArrowForward} from 'react-icons/io';
import img1 from "../../../images/carousel1.webp";
import img2 from "../../../images/carousel2.webp";
import img3 from "../../../images/carousel3.webp";
import img4 from "../../../images/carousel4.webp";
import img5 from "../../../images/carousel5.webp";
import img6 from "../../../images/carousel6.webp";
function Index() {

  return (
    <div className={styles.virtualPresence}>
      <div className={styles.topSection}>
        <StaticImage
          width={42}
          height={42}
          src="../../../svg/3d-spaces-icon.svg"
        />
        <div>
          <h1>Virtual Presence made Real</h1>
          <p>
          Support all your business needs from anywhere, anytime.
          </p>
        </div>
        <StyledButton title="Request access to 3D spaces" extraClass="nobgLink" icon={<IoIosArrowForward />} link="./get-started" email={true}/>
      </div>
      <div
        id="scrollSection"
        className={styles.carousel}
      >
        <div className={styles.contain}>
          <img  src={img1} alt='MasterWizr'/>
          <img  src={img2} alt='MasterWizr'/>
          <img  src={img3} alt='MasterWizr'/>
          <img  src={img4} alt='MasterWizr'/>
          <img  src={img5} alt='MasterWizr'/>
          <img  src={img6} alt='MasterWizr'/>
        </div>
      </div>
    </div>
  );
}

export default Index;
