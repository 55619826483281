import React, {useCallback, useEffect, useState} from 'react';
import * as styles from './newsSection.module.scss';
import {StaticImage} from "gatsby-plugin-image";
import NewsTitle from "../../atoms/newsTitle";
import NewsCard from "../../molecules/newsCard";
import {isOnlyMobileDevice} from "../../../helpers/device-detect"
import {img1} from "../../../images/homepg.jpg"
import {useStaticQuery, graphql} from "gatsby";

const NewsSection = () => {

    const {allWpPost} = useStaticQuery(
        graphql`
          query {
            allWpPost(limit: 3) {
                edges {
                    node {
                        title
                        slug
                    }
                }
            }
        }
    `
    );

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.upper}>
                    <div className={styles.title}>
                        <p>The stories you want to read</p>
                        <a>Super WiZR Magazine</a>
                    </div>
                    <div className={styles.image}>
                        <StaticImage alt="Magazine image" src="../../../images/mw-magazine-img-1.png" />
                    </div>
                    <div className={styles.popularStories}>
                        <p>Advancing presentations</p>
                       
                                        <NewsTitle title="4 new perspectives" link="/blog/4-new-ways-to-look-at-your-presentations" />
                                        <hr/>
                                        <NewsTitle title="6 hacks of improvement" link="/blog/6-steps-to-improve-your-presentation" />
                                        <hr />
                                        <NewsTitle title="10 steps to excellence" link="/blog/10-steps-to-awesome-presentations" />
                         
                    </div>
                </div>
                <div className={styles.lower}>
                    <div className={styles.title}><h3>Trending News</h3></div>
                    <div className={styles.cards}>
                        {/* {
                            allWpPost.edges.map((post) => {
                                return (
                                    <NewsCard link={post.node.slug} title={post.node.title}  />
                                )
                            })
                        } */}
                        <NewsCard/>
                       
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsSection;