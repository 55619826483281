import React from "react";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as styles from "./videoTab.module.scss";

function index({ text, secondText,desc, active=false, margin , id, click}) {
  const playVideo = (id) =>{
    click(id);
  }
  return (
    <div className={styles.maintabs}>
    <div onClick={()=>playVideo(id)} className={styles.tabBtnVideo} style={{marginBottom:`${margin}rem`}}>
      <FontAwesomeIcon icon={faPlay} color="#EC7600" />
      <p style={active ? { color: "#EC7600" } : null}>
        {text} - 
        <span style={active ? { color: "#EC7600" } : null}>
           { secondText}
        </span>
      </p> <br/>
    </div>
      <p  className={styles.desc} style={active===false ? {display:'none'} : {display:'block'}}>
        {desc}
      </p>
      </div>
  );
}

export default index;
