import React, {useCallback, useEffect, useState} from 'react';
import * as styles from './IconSlider.module.scss';
import {StaticImage} from 'gatsby-plugin-image'
import "../../../svg/streaming-view-icon.svg"
import SwipeableViews from "react-swipeable-views";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronRight,
    faChevronLeft
} from '@fortawesome/free-solid-svg-icons';
import StudioIcon from '../../../svg/studio-icon.svg';
import CoverPagesIcon from '../../../svg/cover-pages-icon.svg';
import LibraryIcon from '../../../svg/library-icon.svg';
import ShowRoomsIcon from '../../../images/show-rooms-icon.svg';
import StreamingIcon from '../../../images/master-menu-logo-orange.svg';
// import ModuleItem from '../../../';

const IconSlider = () => {
    const [padding, setPadding] = useState(10);
    const [index, setIndex] = useState(0);

    const handleNavigation = useCallback(
        (e) => {
            const window = e.currentTarget;
            if(window.pageYOffset >= 3600 && window.pageYOffset <= 4100){
                let value = (4000-window.pageYOffset)/15;
                if(value <= 50 && value >= 10){
                    setPadding(value);
                }
            }
        },
        []
    );

    useEffect(() => {
        window.addEventListener("", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    const handleChangeIndex = (index) => {
        setIndex(index);
    };

    const handleChange = (value) => {
        setIndex(value);
    };
    const increaseIndex = () => {
        index === 4 ? setIndex(0) : setIndex(index+1);
    }
    const decreaseIndex = () => {
        index === 0 ? setIndex(4) : setIndex(index-1);
    }
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <h2>Master Samples</h2>
                <div className={styles.icons}>
                    <div className={`${styles.icon} ${index === 0 && styles.selected}`} style={{ padding: `${padding}px` }} onClick={() => handleChange(0)} onKeyDown={() => handleChange(0)}>
                        <div className={`${styles.iconImg}`}>
                            <StreamingIcon />
                        </div>
                        <h4>Master View</h4>
                    </div>
                    <div className={`${styles.icon} ${index === 1 && styles.selected}`} style={{ padding: `${padding}px` }} onClick={() => handleChange(1)} onKeyDown={() => handleChange(1)}>
                        <div className={`${styles.iconImg}`}>
                            <ShowRoomsIcon />
                        </div>
                        <h4>Show Rooms</h4>
                    </div>
                    <div className={`${styles.icon} ${index === 2 && styles.selected}`} style={{ padding: `${padding}px` }} onClick={() => handleChange(2)} onKeyDown={() => handleChange(2)}>
                        <div className={`${styles.iconImg}`}>
                            <CoverPagesIcon />
                        </div>
                        <h4>Cover Pages</h4>
                    </div>
                    <div className={`${styles.icon} ${index === 3 && styles.selected}`} style={{ padding: `${padding}px` }} onClick={() => handleChange(3)} onKeyDown={() => handleChange(4)}>
                        <div className={`${styles.iconImg}`}>
                            <StudioIcon />
                        </div>
                        <h4>Studio</h4>
                    </div>
                    <div className={`${styles.icon} ${index === 4 && styles.selected}`} style={{ padding: `${padding}px` }} onClick={() => handleChange(4)} onKeyDown={() => handleChange(5)}>
                        <div className={`${styles.iconImg}`}>
                            <LibraryIcon />
                        </div>
                        <h4>Library</h4>
                    </div>
                </div>
                <div className={styles.slider}>
                    <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
                     <>
                     <StaticImage
                            src="../../../images/master-menu.png"
                            alt="Show Rooms Image"
                            className={styles.sliderImg}
                            // filename="show-rooms-image.png"
                            // desc = "lorem ipsum dolor sit amet"
                        />
                     </>
                     <>
                        <StaticImage
                            // src="../../../images/new-showroom-1.png"
                            // filename="showrooms.png"
                            // desc = "Master WiZR is a revolutionary communication tool that makes all meetings more professional, engaging, and efficient whether online and in-person"
                            // alt="Show Rooms Image"
                            // className={styles.sliderImg}
                            src="../../../images/showrooms.png"
                            alt="Show Rooms Image"
                            className={styles.sliderImg}
                        />
                     </>
                     <>
                        <StaticImage
                            src="../../../images/cover page.png"
                            alt="Show Rooms Image"
                            className={styles.sliderImg}
                        />
                        </>
                        <>

                        <StaticImage
                            src="../../../images/studio.png"
                            alt="Show Rooms Image"
                            className={styles.sliderImg}
                        />
                        </>
                        <>

                        <StaticImage
                            src="../../../images/Library.png"
                            alt="Show Rooms Image"
                            className={styles.sliderImg}
                        />
                        </>
                    </SwipeableViews>
                    <div className={styles.arrows}>
                        <div className={styles.leftContainer} onClick={() => decreaseIndex()} onKeyDown={() => decreaseIndex()}>
                            <div className={styles.arrow}>
                                <FontAwesomeIcon icon={faChevronLeft} style={{height:20, width:12, color:'#D0D0D0'}}/>
                            </div>
                        </div>
                        <div className={styles.rightContainer} onClick={() => increaseIndex()} onKeyDown={() => increaseIndex()}>
                            <div className={styles.arrow}>
                                <FontAwesomeIcon icon={faChevronRight} style={{height:20, width:12, color:'#D0D0D0'}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IconSlider;
