import React from 'react';
import * as styles from "./ShowRooms.module.scss";
import HorizontalScroll from "../../atoms/HorizontalScroll";
import StyledButton from "../../atoms/StyledButton";
import {IoIosArrowForward} from "react-icons/io";
import ShowRoomsIcon from '../../../svg/show-rooms-icon.svg';

const ShowRooms = () => {
    return (
        <div className={styles.showRooms} >
        <div className={styles.container} >
            <div className={styles.title} id="showRooms">
                <ShowRoomsIcon />
                <h2>Show Rooms</h2>
            </div>
            <h1>MASTER WiZR sets the new standard for client communication best practices</h1>
            {/* <StyledButton title="Try free trial today" extraClass="bgShadow" icon={<IoIosArrowForward />} link="https://beta-studio.mwstream.com/control-panel/cover-pages" email={true}/> */}
            <HorizontalScroll />
        </div>
        </div>
    );
};

export default ShowRooms;
