import React, { useEffect, useRef, useState } from "react";
import * as styles from "./visualConnection.module.scss";
import img from "../../../images/img-visual-connection.jpg";
import onScreen from "../../../hooks/onScreen";
import {isOnlyMobileDevice} from '../../../helpers/device-detect'
import {IoIosArrowForward} from 'react-icons/io';
import StyledButton from "../../atoms/StyledButton";


const Index = () => {
  const ref = useRef();
  const isVisible = onScreen(ref);
  const [heightEl, setHeightEl] = useState(null);
  const [imgWidth, setImgWidth] = useState(0);
  const [pageYOffsetState, setPageYOffsetState] = useState(0);
  const [ovarlayWidthBackgorund, setOvarlayWidthBackgorund] = useState(0);
  const [marginExtra, setMarginExtra] = useState(0);

  useEffect(()=>{
    isOnlyMobileDevice() ? setMarginExtra(5) : setMarginExtra(0)
  },[])
  if (isVisible) {
    window.onscroll = () => {
      //desktop animation
      let pageYOffset = window.pageYOffset;
      setPageYOffsetState(window.pageYOffset);
      if (heightEl === null) {
        setHeightEl(pageYOffset + 720);
      }
      if (pageYOffset >= 650 && pageYOffset <= 1155) {
        const imgWidth = ((pageYOffset - 300) * 100) / 1155;
        if (pageYOffset > 650) {
          setImgWidth(imgWidth);
        }
      } else if (pageYOffset <= 650) {
        setImgWidth(0);
        if (pageYOffset >= 320) {
          let calWidth = pageYOffset;
          setOvarlayWidthBackgorund(calWidth/15);
          
        }
      } else {
        setImgWidth(100);
      }

      //Mobile animations 636 1100
      if(isOnlyMobileDevice()){
        if(pageYOffset > 625 && pageYOffset <= 1000){
          setMarginExtra(5)
        }
        else if(pageYOffset > 1000){
          setMarginExtra(0)
        }
        
      }
    };
  }
  return (
    <div className={styles.visualConnection}>
      <div ref={ref} className={styles.visualContainer}>
        <div className={styles.description}>
          <h2 style={{marginBottom:`${3+marginExtra}rem`}}>Visual Connection</h2>
          <p style={{marginBottom:`${3+marginExtra}rem`}}>
          How can you work significantly faster, better, deeper and present your work in a more intuitive way? Master WiZR transforms meetings, and give you a competitive advantage.
          </p>
          <StyledButton title="Get access to products" extraClass="nobgLink" link='/get-started'  icon={<IoIosArrowForward />} />
          
          <hr />
        </div>
        <div className={styles.logo}>
          <div
            className={styles.containVisual}
            style={{ width: `${imgWidth}%` }}
          >
            {pageYOffsetState >= 350 ? (
              <div
                className={styles.overlayImg}
                style={{ width: `${200 - imgWidth * 2}px` }}
              ></div>
            ) : null}
            <img className={isVisible && styles.desktopV} src={img} alt='MasterWizr' />
          </div>
          <div
            className={styles.overlayBackground}
            style={{ width: `${ovarlayWidthBackgorund}%` }}
          ></div>
        </div>
      </div>
      {/* <div
          className={styles.overlayButon}
          style={{ height: 74 - imgWidth }}
        ></div> */}
    </div>
  );
};

export default Index;
