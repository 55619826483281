import * as React from "react";
import { useEffect } from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import PerfectScrollbar from 'react-perfect-scrollbar'
import Explore from "../components/atoms/explore";
import FreeTrial from "../components/atoms/freeTrial";
import Banner from "../components/molecules/banner";
import ControlPanel from "../components/molecules/controlPanel";
import VisualConnection from "../components/molecules/visualConnection";
import TutorialVideos from "../components/molecules/tutorialVideos";
import Offers from "../components/molecules/offers";
import IconSlider from "../components/molecules/iconSlider";
import Services from "../components/molecules/services";
import VisualPresence from "../components/ogranisms/virtualPresence";
import PresentLike from "../components/molecules/presentLike";
import PresentionPortal from "../components/molecules/presentationPortal";
import NewsSection from "../components/ogranisms/newsSection";
import FaqSection from "../components/molecules/FaqSection";
import AOS from "aos";
import "aos/dist/aos.css";
import OpenPositions from "../components/atoms/OpenPositions";
import ShowRooms from "../components/molecules/ShowRooms";
import Head from "../components/ogranisms/head";
import WhySlider from "../components/atoms/WhySlider";
const IndexPage = () => {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  return (
    <>
      <Head
        title="Home ⋆ Master Wizr - Revolutionizing Presentations Today"
        description="Revolutionizing Presentations - Cutting Edge Sharing for Business. Get your MASTER WiZR free trial today. It is the best presentation tool."
        ogTitle = "Home"
        
      />
      <Header />
      <main>
        <Banner />
        <Explore />
        <VisualConnection />
        <ControlPanel />
        <TutorialVideos />
        <OpenPositions />
        <IconSlider />
        <VisualPresence />
        <Services />
        <PresentLike />
        <PresentionPortal />
        <ShowRooms />
        <Offers />
        <NewsSection />
        {/* <WhySlider /> */}
        <FaqSection />
        <FreeTrial />
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
