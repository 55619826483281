import React, {useEffect, useState} from 'react';
import Service from '../../atoms/service';
import * as styles from './services.module.scss';
import SwipeableViews from "react-swipeable-views";
import Pagination from "../../atoms/Pagination";

const Services = () => {
    const [width, setWidth] = useState(0);
    const [index, setIndex] = useState(0);
    const handleChangeIndex = (index) => {
        setIndex(index);
    };
    useEffect( () => {
        setWidth(window.innerWidth);
    },[]);

    const mobileView = () => {
        return (
            <div>
                <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
                    <Service  extraClass="first" number="1" title="Goals & Material" description="You can easily use MASTER WiZR out of the box. Or, take the next step towards perfection of: Materials, Content and Communication. Join in a dialogue with us." />
                    <Service  extraClass="second" number="2" title="Harmonizing or Revolutionizing" description="Experience how your material of presentations, videos, visuals and other text can be moved to a new level. Harmonizing includes a practical visual clean-up. Revolutionizing takes it up 10X." />
                    <Service  extraClass="third" number="3" title="Organizing & Uploading" description="Working with goals that matter and material that is improved we can assist in organizing and uploading. Find the best path of use and how it changes meetings and processes is better through dialogue." />
                    <Service  extraClass="fourth" number="4" title="Expanding Portals " description="Your goals, processes and material are supported by portals. Combining many master portals, you will find a new way to view, enjoy and share material that matters. Let us share the best practices" />
                </SwipeableViews>
                <Pagination dots={4} index={index}/>
            </div>
        );
    }

    const desktopView = () => {
        return (
          <div className={styles.desktopContainer}>
              <Service  animation="fade-right" delay="50" extraClass="first" number="1" title="Goals & Material" description="You can easily use MASTER WiZR out of the box. Or, take the next step towards perfection of: Materials, Content and Communication. Join in a dialogue with us." />
              <Service animation="fade-left" delay="100" extraClass="second" number="2" title="Harmonizing or Revolutionizing" description="Experience how your material of presentations, videos, visuals and other text can be moved to a new level. Harmonizing includes a practical visual clean-up. Revolutionizing takes it up 10X." />
              <Service  animation="fade-right" delay="250" extraClass="third" number="3" title="Organizing & Uploading" description="Working with goals that matter and material that is improved we can assist in organizing and uploading. Find the best path of use and how it changes meetings and processes is better through dialogue." />
              <Service animation="fade-left" delay="200" extraClass="fourth" number="4" title="Expanding Portals " description="Your goals, processes and material are supported by portals. Combining many master portals, you will find a new way to view, enjoy and share material that matters. Let us share the best practices" />
          </div>
        );
    }

    return (
        <div className={styles.container}>
            <h2>Inspired MW Collaborations </h2>
            {width && width < 1024 ? mobileView() : desktopView()}
        </div>
    );
};

export default Services;
