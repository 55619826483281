import React, { useEffect } from "react";
import BannerText from "../../atoms/bannerTekst";
import * as styles from "./banner.module.scss";
import HomeSwiper from "../../atoms/HomeSwiper";
import StyledButton from "../../atoms/StyledButton";
import { FaAngleRight } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
function Index() {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  return (
    <div className={styles.bannerContain}>
      <div className={styles.text}>
        <BannerText />
      </div>
      {/* <HomeSwiper /> */}
    </div>
  );
}

export default Index;
